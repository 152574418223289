import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, styled, Typography } from '@mui/material';
import AlertMessage from 'app/components/Alert';
import Toast from 'app/components/Toast';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IDataEan, IDataEanErrors } from 'app/types/data/IDataSku';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { initialValues } from '..';
import useAuth from 'app/hooks/useAuth';
import { EanSchema } from '../../@Schemas/EanSchema';
import Ean_Api from 'app/api/Ean_Api';
import { IFormEan } from '../../@Types/IFormEan';
import EanInput from 'app/components/Inputs/EanInput/EanInput';
import { LoadingButton } from '@mui/lab';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const UPDATE_MSG = getMessage('Sku', 'update');
const CREATE_MSG = getMessage('Sku', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const SkuEanForm = (props: IFormEan) => {
  const [data, setData] = React.useState<IDataEan>(props.data || initialValues);
  const { getConfigCampo } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  //const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  useTrailHistory({
    selected: data,
    selectedName: data?.nr_ean,
    displayName: 'Ean',
    toggleView: () => { },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm<IDataEan>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(EanSchema),
  });

  React.useEffect(() => {
    setDataValue(props.data, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataEan) {

    setLoading(true);

    try {
      const campos = [{
        nome: 'Ean',
        campo: 'nr_ean'
      }
      ]

      campos.forEach(x => {
        if (!data[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required && data[x.nome] !== "ID")
          return setToast({
            open: true,
            message: `Campo ${x.nome} obrigatório`,
            severity: 'error',
          })
      })

      var newData: IDataEan;
      newData = await Ean_Api.create(data, props.relation);
      if (props.onSubmit) props.onSubmit();
      setData(newData);
      setDataValue(newData, setValue);
      setToast({
        open: true,
        message: data.id ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });

    }
    catch (error: IDataEanErrors | unknown) {
      if (error) {
        const err = error as IDataEanErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    }
    finally {
      setLoading(false);
    }

  }

  return (
    <FormBox id="formik-Sku-eans" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/*Fields*/}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>

        {getConfigCampo && !getConfigCampo('Ean', props.campos ?? []).hidden &&
          <Grid item xs={12} md={12}>
            <Controller
              name="nr_ean"
              control={control}
              defaultValue={data.nr_ean}
              render={({ field: { onChange } }) => (
                <EanInput
                  id="nr_ean"
                  label="Código de barras"
                  type="text"
                  value={data.nr_ean || ''}
                  autoComplete={'off'}
                  fullWidth
                  inputProps={{
                    maxLength: 191,
                  }}
                  InputLabelProps={{ shrink: Boolean(data.nr_ean) }}
                  variant="outlined"
                  size="small"
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.nr_ean?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, nr_ean: e.target.value });
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
        }
      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.onCancel} color="primary">
          Voltar
        </Button>
        <LoadingButton disabled={props.editDisabled} type="submit" color="primary" loading={loading} variant="contained">
          {props.typeForm && props.typeForm === 'create' && props.sequency ? (<>Salvar e continuar</>) : <>Salvar</>}
        </LoadingButton>
      </Grid>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default SkuEanForm;
