import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IDataEan,
  IDataSkuErrors,
  IEanFilter,
  IEanPagination,
  IHookFormSku,
  SkuFields,
} from 'app/types/data/IDataSku';
import { AxiosError } from 'axios';
import { default as qs } from 'qs';
import Model_Api from './Model_Api';
import Api from './api';
import { EanRelations } from '../types/data/IDataSku';

export default class Ean_Api extends Model_Api {
  static self = new Ean_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataSkuErrors {
    const resp: IDataSkuErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormSku[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as SkuFields;
      const item: IHookFormSku = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IEanFilter,
    relation?: IDataRelation<EanRelations>
  ): Promise<IEanPagination> {
    try {
      if (relation) {
        const resp = (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/ean`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        return resp as IEanPagination;
      } else {
        const resp = (
          await Api.get(Endpoints.Ean.main, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        return resp as IEanPagination;
      }
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Eans', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataEan) {
    try {
      return (await Api.get(`${Endpoints.Sku.main}/${data.id}`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Skus', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataEan,
    relation?: IDataRelation<EanRelations>,
    config?: { params?: Record<string, string> } // Novo parâmetro opcional para query strings
  ): Promise<IDataEan> {
    try {
      let url: string;
      if (relation) {
        url = `${Endpoints[relation.relation].main}/${relation.id}/${Endpoints.Ean.main}`;
      } else {
        url = Endpoints.Ean.main;
      }

      // Adicionando parâmetros de query string à URL
      if (config?.params) {
        const queryString = new URLSearchParams(config.params).toString();
        url += `?${queryString}`;
      }

      const payload = {
        ...data,
      };

      const resp: IDataEan = (
        await Api.post(url, payload)
      ).data.ean as IDataEan;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Eans', handleErros);
      throw handleErros;
    }
  }

  static async delete(data: IDataEan, relation?: IDataRelation<EanRelations>): Promise<IDataEan> {
    try {
      var resp: IDataEan;

      if (relation)
        resp = (
          await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/ean/${data.id}`)
        ).data.sku;
      else resp = (await Api.delete(`${Endpoints.Sku.main}/${data.id}`)).data.sku;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Skus', handleErros);
      throw handleErros;
    }
  }
}
