import { Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Sku_Api from 'app/api/Sku_Api';
import useAuth from 'app/hooks/useAuth';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import CatalogProductContext from 'app/contexts/CatalogProduct';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { useToast } from 'app/hooks/useToast';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import { EanRelations, IDataEan, IDataSkuErrors } from 'app/types/data/IDataSku';
import { getMessage } from 'app/utils/messages';
import React, { Fragment, useEffect } from 'react';
import GridColumns from './Columns/GridColumns';
import handleOnSortModelChange from 'app/utils/handleOnSortModelChange';
import selectRow from 'app/utils/selectRow';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import axios from 'axios';
import Ean_Api from 'app/api/Ean_Api';
import EanFormContainer from '../EanFormContainer';
import { IDataEanErrors } from '../../../../../types/data/IDataSku';
const TableMUIComponent = styled(TableMUI)(() => ({
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    //display: 'none',
  },
}));

//const header = GridColumns.map((item) => item.field);

const UPDATE_MSG = getMessage('Ean', 'update');
const DELETE_MSG = getMessage('Ean', 'delete');
const DELETE_MULT_MSG = getMessage('Eans', 'delete', 'os');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplayEan {
  relation?: IDataRelation<EanRelations>;
  campos?: IDataBpmCampo[]
  sequency?: any
  onSubmit?: () => void
}


const DisplayEan = (props: IDisplayEan) => {

  const { handleResetForm } = useUtilsContext();
  const productCtxt = React.useContext(CatalogProductContext);
  const { permissionsTags, showAction } = useAuth();
  const sequencyName = localStorage.getItem('sequencyName')
  const [isGridView, toggleView] = React.useState(!(sequencyName && sequencyName.toLowerCase().includes('skus')));
  const [typeForm, setTypeForm] = React.useState('');
  const [data, setData] = React.useState<IDataEan[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataEan | undefined>(undefined);
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const [multipleSelected, setMultipleSelected] = React.useState<string[] | number[]>([]);
  const [isCheckboxOn, setIsCheckboxOn] = React.useState<boolean>(false);
  const [isAllPageRowsSelected, selectAllPageRows] = React.useState<boolean | null>(false);
  const [wasSelectAllSkuClicked, setWasSelectAllSkuClicked] = React.useState<boolean>(false);


  useAuth();

  const { showToast } = useToast();

  const { uuid } = useTrailHistory({
    selected,
    selectedName: selected?.nr_ean,
    displayName: 'EANs',
    toggleView,
  });

  const [, setIsNewDocker] = React.useState<string>();

  const currentSelectedRowIndex = data?.findIndex((item) => item?.id === selected?.id);

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  useEffect(() => {
    const fetchConfig = async () => {
      if (!process.env.REACT_APP_API_URL) {
        try {
          const response = await axios.get("/config.json");
          const urlArray = response.data.REACT_APP_API_URL.split("/");
          urlArray.pop();  // Remove o último elemento
          const newUrl = urlArray.join("/");  // Rejunta o array em uma string
          setIsNewDocker(newUrl);
        } catch (error) {
          console.error("Error fetching config:", error);
        }
      } else {
        setIsNewDocker("api");
      }
    };

    fetchConfig();
  }, []);

  React.useEffect(() => {

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params]);

  React.useEffect(() => {

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation?.id]);

  React.useEffect(() => {
    if (!isCheckboxOn) {
      setMultipleSelected([]);
      selectAllPageRows(false);
    }
  }, [isCheckboxOn]);

  React.useEffect(() => {
    if (sequencyName && sequencyName.toLowerCase().includes('eans')) {
      toggleView(false);
      setSelected(undefined);
      setTypeForm('create')
    }

    return () => {
      const sequencyName = localStorage.getItem('sequencyName')
      if (sequencyName && sequencyName.toLowerCase().includes('eans')) {
        localStorage.removeItem('sequencyName')
      }
    };
  }, [sequencyName]);

  async function load() {
    setLoading(true);
    try {
      setData([]);


      const eans = await Ean_Api.list(
        {
          q: search,
          page: page + 1,
          per_page: pageSize,
          ...params,
        },
        props.relation
      );

      if (isCheckboxOn) {
        setMultipleSelected(eans?.data?.map((item) => item.id as string) || []);
        setWasSelectAllSkuClicked(false);
      }


      setData(eans.data);
      setTotal(eans.pagination.total);
      setTotalPages(eans.pagination.total_pages);
      selectRow<IDataEan>({
        data: eans.data,
        selected: selected ? eans.data.find(f => f.id === selected.id) : selected,
        currentSelectedRowIndex,
        rowToSelect,
        setSelected,
      });

    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }


  function handleCloseToast() {
    setToast({ open: false, ...initialToast });
  }

  async function handleDelete() {
    try {
      const hasMultipleDelete = multipleSelected.length > 0;
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (hasMultipleDelete) {

        await Promise.all(multipleSelected.map((id) => Ean_Api.delete(id, props.relation)));
      }
      else if (selected) await Ean_Api.delete(selected, props.relation);
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({
        open: true,
        message: hasMultipleDelete ? DELETE_MULT_MSG : DELETE_MSG,
        severity: initialToast.severity,
      });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataEanErrors | unknown) {
      const err = error as IDataEanErrors;
      if (err.errors.status && err.errors.message)
        setAlertModal({
          open: true,
          title: `${err.errors.status} - Não foi possível prosseguir`, confirmTextButton: 'OK',
          message: err.errors.message,
          onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
        });
    }
  }

  function setDeleteModal() {
    let title = `${props.relation ? 'Retirar' : 'Remover'} EAN`;
    let msg = (
      <span>
        Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} o ean{' '}
        <strong>
          {selected?.id} - {selected?.nr_ean}
        </strong>
        ?
      </span>
    );

    if (multipleSelected.length > 0) {
      title = `${props.relation ? 'Retirar' : 'Remover'} EANs`;
      msg = (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'}{' '}
          <strong>todos os Eans selecionados</strong>?
        </span>
      );
    }

    setAlertModal({
      open: true,
      title: title,
      message: msg,
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }


  function onFilter(filters: IParamsQs) {
    setPage(0);
    setSelected(undefined);
    setRowToSelect(null);
    setParams({ ...filters, page: 0 });
    setIsCheckboxOn(false);
    setMultipleSelected([]);
    selectAllPageRows(false);
  }


  // async function doExport() {
  //   setLoading(true);
  //   try {
  //     await Sku_Api.export(
  //       {
  //         q: search,
  //         page: 1,
  //         per_page: total,
  //         ...params,
  //       },
  //       props.relation
  //     );
  //   } catch (error: IDataSkuErrors | unknown) {
  //     if (error) {
  //       const err = error as IDataSkuErrors;
  //       if (err.errors?.status && err.errors?.message) {
  //         setAccessError({ code: err.errors.status, message: err.errors.message });
  //       }
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // }


  const ToolbarSelectionMessage = () => (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {multipleSelected.length < data.length && (
        <span>{`${multipleSelected.length} registro${multipleSelected.length >= 2 ? 's' : ''
          } selecionado${multipleSelected.length >= 2 ? 's' : ''}.`}</span>
      )}
      {!wasSelectAllSkuClicked &&
        data?.length < total &&
        multipleSelected?.length > 0 &&
        multipleSelected?.length === data.length && (
          <span>{`Todos os ${multipleSelected.length} registros estão selecionados.`}</span>
        )}
      {!wasSelectAllSkuClicked &&
        data?.length === total &&
        multipleSelected?.length === total &&
        multipleSelected.length === data.length && (
          <span>{`Todos os ${total} registros estão selecionados.`}</span>
        )}
      {wasSelectAllSkuClicked && multipleSelected?.length === data?.length && (
        <span>{`Todos os ${total} registros estão selecionados.`}</span>
      )}
      {multipleSelected.length < total && !isAllPageRowsSelected && (
        <Button
          onClick={() => {
            setMultipleSelected(data.map((item) => item?.id as string));
            setWasSelectAllSkuClicked(true);
            selectAllPageRows(true);
          }}
        >
          {`Selecionar todos os ${total} registros`}
        </Button>
      )}
      {((wasSelectAllSkuClicked && data.length === multipleSelected.length) ||
        (data.length === total && multipleSelected.length === total)) && (
          <Button
            onClick={() => {
              setWasSelectAllSkuClicked(false);
              selectAllPageRows(false);
              setMultipleSelected([]);
              setIsCheckboxOn(false);
            }}
          >
            Limpar seleção
          </Button>
        )}
    </div>
  );

  const ToolbarComponent = (props) => (
    <>
      <Toolbar
        options={{
          total: {
            value: total,
            total_pages: totalPages,
          },
          view: {
            state: isGridView ? 'grid' : 'form',
          },
          filter: {
            columns: productCtxt
              ? GridColumns.filter((cols) => cols.field !== 'produto')
              : GridColumns,
            onFilter: onFilter,
          },
          page: page + 1,
          //isLoadingAllGridRows: isLoadingAllGridRows,
          isLoadingGrid: loading,
          data: data,
          isCheckboxOn: isCheckboxOn,
          multipleSelected: multipleSelected,
          selectedRow: selected,
          typeItem: 'sku'
        }}
        onAddClick={() => {
          setSelected(undefined);
          toggleView(false);
          setTypeForm('create');
        }}
        onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
        onClickView={() => toggleView(!isGridView)}
        onClickRefresh={() => load()}
        onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
        //onClickAttach={() => toggleAttachModal(true)}
        searchValue={search}
        onSearchChange={(value) => setSearch(value)}
        onResetForm={() => handleResetForm('productForm')}
        navigate={(value) => {
          setSelected(value);
        }}
        navigateToPage={(page, rowToSelect = 'first') => {
          setRowToSelect(rowToSelect);
          setPage(page - 1);
        }}
        selectAllPageRows={(isAllPageRowsAlreadySelected) => {
          if (isAllPageRowsAlreadySelected === false) {
            setMultipleSelected([]);
          }
          setIsCheckboxOn((prev) => !prev);
          setMultipleSelected(data.map((item) => item.id as number));
        }}
        {...props}
      />
      {isCheckboxOn && !!multipleSelected?.length && !loading && <ToolbarSelectionMessage />}
    </>
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3}>
          {!isGridView && <ToolbarComponent />}
          {isGridView && (
            <TableMUIComponent

              checkboxSelection={isCheckboxOn}
              onCellDoubleClick={(event) => {
                if (event.field === 'id' && showAction({ action: 'edit', tag: 'sku' }, permissionsTags)) {
                  toggleView(false);
                }
              }}
              columns={GridColumns}
              rows={data}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => {
                setIsCheckboxOn(false);
                setWasSelectAllSkuClicked(false);
                setMultipleSelected([]);
                setPage(newPage);
                setRowToSelect(null);
              }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={(model) => {
                handleOnSortModelChange(model, setParams, setRowToSelect);
              }}
              autoHeight
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                if (isCheckboxOn) {
                  selectAllPageRows(null);
                  setMultipleSelected(newSelection as string[]);
                }
                const row = data.filter((item) => item.id === newSelection[0]);
                setSelected(row[0]);
              }}
              selectionModel={isCheckboxOn ? multipleSelected : [selected?.id as number]}
              customToolbar={() => <ToolbarComponent hasExportButton isGridRef />}
            />
          )}

          {!isGridView && (
            <EanFormContainer
              uuid={uuid}
              relation={props.relation}
              setSelected={(data) => setSelected(data)}
              data={selected}
              onSubmit={() => {
                if (props.sequency && props.onSubmit) {
                  props.onSubmit()
                } else {
                  load()
                  // setSelected(item)
                }
              }}
              cancel={() => toggleView(!isGridView)}
              editDisabled={!showAction({ action: 'edit', tag: 'sku' }, permissionsTags)}
              campos={props.campos}
              sequency={props.sequency}
              typeForm={typeForm}
            />
          )}
        </Grid>

        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
          confirmTextButton={alertModal.confirmTextButton}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayEan;
