import FormContainer from 'app/components/Containers/FormContainer';
import { IDataRelation } from 'app/types/data/IData';
import { IDataEan, } from 'app/types/data/IDataSku';
import React from 'react';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import { EanRelations } from '../../../../../types/data/IDataSku';
import EanContext from 'app/contexts/EanContext';
import SkuEanForm from 'app/components/Forms/Sku/ean';
declare interface IEanFormContainer {
  data?: IDataEan;
  relation?: IDataRelation<EanRelations>;
  onSubmit: (item: any) => void;
  cancel: () => void;
  editDisabled?: boolean;
  setSelected: (data) => void;
  uuid?: string;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined

}

const EanFormContainer = (props: IEanFormContainer) => {
  const [data, setData] = React.useState<IDataEan | undefined>(props.data);

  React.useEffect(() => {
    setData(props.data)
  }, [props.data]);

  return (
    <EanContext.Provider value={data}>
      <FormContainer
        uuid={props?.uuid}
        mainForm={
          <SkuEanForm
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            editDisabled={props.editDisabled}
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            sequency={props.sequency}
            campos={props.campos}
            typeForm={props.typeForm}
          />
        }
      />
    </EanContext.Provider>
  );
};

export default EanFormContainer;
